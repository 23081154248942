.App {
  text-align: center;
  background-color: white;
}

body {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}
